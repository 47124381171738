
import { computed, reactive, ref, defineComponent } from "vue"
import { useRouter, useRoute } from "vue-router"
import TabsComponent from "../navigation/TabsComponent.vue"

export default defineComponent({
  components: { TabsComponent },
  props: {},
  setup(props) {
    const tabItems = reactive([
      { label: "Overview", routeName: "salesQuotations" },
      {
        label: "Product management",
        routeName: "salesProductManagement",
      },
      {
        label: "Products overview",
        routeName: "productOverview",
      },
      {
        label: "Templates",
        routeName: "salesTemplates",
      },
      /*{
        label: "Quotation editor",
        routeName: "salesQuotationEditor",
      },*/
    ])

    return { tabItems }
  },
})
